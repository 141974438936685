import { Login } from "./components/Login";
import { Container } from "react-bootstrap";

export default function Root(props) {
  return (
    <Container>
      <div className="login">
        <Login {...props} />
      </div>
    </Container>
  );
}
