import { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import IAuthData from "../types/login.type";
import AuthDataService from "../services/auth.service";
import ConfigEnviroment from "../config/ConfigEnviroment";
const logo = require("../styles/images/logo_color.svg");

export const Login = () => {
  const [user, setUser] = useState("");
  const [userError, setUserError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const [cargando, setCargando] = useState(false);

  const userRef = useRef(null);
  const passRef = useRef(null);

  const onClick = () => {
    const loginData: IAuthData = {
      username: user,
      password: password,
    };
    access(loginData);
  };

  const access = (data: IAuthData) => {
    setCargando(true);
    AuthDataService.login(data)
      .then((response: any) => {
        sessionStorage.clear();
        localStorage.setItem("user", response.data.userId);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("userName", data.username);
        localStorage.setItem(
          "permisos",
          JSON.stringify(response.data.functionalities)
        );
        window.location.href = ConfigEnviroment.getHomeUrl();
      })
      .catch((e: Error) => {
        setCargando(false);
        setUserError(true);
        setPasswordError(true);
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (event.target.name === "inputUser") {
        passRef.current.focus();
      } else if (event.target.name === "inputPassword") {
        onClick();
      }
    }
  };

  return (
    <>
      <img src={logo} className="logo" alt="froneus-logo-layout" />

      <div className="field">
        <label htmlFor="in">Email o usuario</label>
        <InputText
          placeholder="Email o usuario"
          className={userError ? "p-invalid block" : "input"}
          id="inputUser"
          value={user}
          onChange={(e) => setUser(e.target.value)}
          ref={userRef}
          name="inputUser"
          onKeyDown={handleKeyPress}
          autoFocus
        />
        {userError && (
          <small id="inputUser-help" className="p-error block">
            El email o usuario es inválido.
          </small>
        )}
      </div>

      <div className="field">
        <label htmlFor="pass">Contraseña</label>
        <InputText
          type="password"
          className={
            passwordError
              ? "p-invalid block password-input"
              : "input password-input"
          }
          placeholder="Contraseña"
          id="inputPassword"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          ref={passRef}
          name="inputPassword"
          onKeyDown={handleKeyPress}
        />
        {passwordError && (
          <small id="inputPassword-help" className="p-error block">
            La contraseña es inválida.
          </small>
        )}
      </div>

      <Button
        className="p-button"
        label={cargando ? "" : "Iniciar sesión"}
        icon={cargando ? "pi pi-spin pi-spinner" : ""}
        disabled={cargando}
        type="button"
        onClick={onClick}
      ></Button>

      <div className="icons">
        <a target="_blank" href="https://ar.linkedin.com/company/froneus">
          <i className="pi pi-linkedin"></i>
        </a>
        {/* <i className="pi pi-instagram"></i>
              <i className="pi pi-facebook"></i>
              <i className="pi pi-twitter"></i> */}
      </div>
    </>
  );
};
