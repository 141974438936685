const config = require("./config.json");
const env = process.env.NODE_ENV || "local";
const config_env = config[env];

class ConfigEnviroment {
  getGatewayUrl() {
    let port = config_env.gateway.port;
    if (port != "") {
      return `${config_env.gateway.host}:${config_env.gateway.port}`;
    } else {
      return `${config_env.gateway.host}`;
    }
  }

  getRootUrl() {
    let port = config_env.root.port;
    if (port != "") {
      return `${config_env.root.host}:${config_env.root.port}`;
    } else {
      return `${config_env.root.host}`;
    }
  }

  getHomeUrl() {
    return this.getRootUrl() + "/inicio";
  }

  getCampaingListUrl() {
    return this.getRootUrl() + "/listado";
  }
}

export default new ConfigEnviroment();
